<template>
  <div>
    <Empty>
      <div class="no-dao-pop">
        <div class="title">{{ $t('dao.noDaotitle') }}</div>
        <div class="button-group">
          <div class="btn1 btn" @click="$gbUtils.handleToPage('/dao')">
            {{ $t('dao.joinDao') }}
          </div>
          <div class="btn2 btn" @click="$gbUtils.handleToPage('/createDao')">
            {{ $t('dao.createDao') }}
          </div>
        </div>
      </div>
    </Empty>
  </div>
</template>

<script>
import Empty from '@/components/common/Empty.vue'
export default {
  components: { Empty },
  name: '',
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.no-dao-pop {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  padding: 30px 10px;
  background: $main-bg;
  // background: rgba(#fff, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  .button-group {
    margin-top: 35px;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      background: $blue-bg;
      height: 39px;
      line-height: 39px;
      border-radius: 8px;
      margin: 0 10px;
    }
    .btn1 {
      flex: 1;
      background: $linear-bg3;
    }
    .btn2 {
      flex: 1;
      background: $linear-bg3;
    }
  }
}
</style>
