<template>
  <div class="dao-detail">
    <NoDao v-if="myDaoList.length === 0"></NoDao>
    <div class="detail-wrap" v-else>
      <DetailHeader
        :daoList="myDaoList"
        v-model="currentDao.name"
        :dataSource="currentDao"
        @show="handleDetailShow"
        @change="handleChangeDaoAt"
      ></DetailHeader>
      <ProposalItem
        v-for="item in proposalList"
        :key="item.title"
        :dataSource="item"
        :tokenName="tokenName"
      >
      </ProposalItem>
      <DaoDetail
        v-if="detailShow"
        @close="handleDetailShow"
        :dataSource="currentDao"
        :lang="lang"
      ></DaoDetail>
    </div>
    <Empty v-if="myDaoList.length > 0 && proposalList.length <= 0" />
  </div>
</template>

<script>
import Empty from '../../components/common/Empty.vue'
import ContractDao from '@/contracts/contractDao'
import ContractToken from '@/contracts/contractToken'
import NoDao from './components/NoDao.vue'
import DaoDetail from '../dao/components/DaoDetail.vue'
import DetailHeader from './components/DetailHeader.vue'
import ProposalItem from './components/ProposalItem.vue'
import { mapState, mapActions } from 'vuex'
export default {
  name: '',
  data () {
    return {
      detailShow: false,
      isHaveDao: true,
      currentDao: {},
      tokenName: '',
      daoAt: -1,
      currentIndex: -1,
      daoList: [],
      myDaoList: [],
      proposalList: []
    }
  },
  methods: {
    ...mapActions(['chanegDaoList']),
    init () {
      this.getDaoList()
    },
    async getDaoList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.daoList()
      this.$emit('changeLoading', false)
      this.daoList = resp.result || []
      if (this.daoList.length <= 0) return
      this.getUserList()
    },
    async getUserList () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.userDaoList()
      this.$emit('changeLoading', false)
      if (!resp.success) return
      resp.result.forEach((item, index) => {
        if (item) {
          if (this.daoAt < 0) this.daoAt = index
          const obj = { ...this.daoList[index] }
          obj.name = this.lang === 'ZH' ? obj.params[1] : obj.params[2]
          obj.daoAt = index
          this.myDaoList.push(obj)
        }
      })
      this.chanegDaoList(this.myDaoList)
      if (!this.myDaoList[0]) return
      this.currentIndex = localStorage.getItem('daoIndex') || 0
      if (this.myDaoList.length <= this.currentIndex) this.currentIndex = 0
      this.currentDao = this.myDaoList[this.currentIndex]
      localStorage.setItem('daoIndex', this.currentIndex)
      this.getTokenInfo()
      this.handleProposalList(this.currentDao.daoAt)
    },
    async handleProposalList (daoAt) {
      this.proposalList = []
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.prosalList(daoAt)
      this.$emit('changeLoading', false)
      const newArr = resp.result
      const nowTime = new Date().getTime() / 1000
      // timeStatus: 0 // 1未开始 0投票中 2已结束
      newArr.forEach((item, index) => {
        const obj = { ...item }
        obj.index = index
        obj.startTime = item.uintParams[3]
        obj.endTime = item.uintParams[4]
        if (nowTime < +obj.startTime) {
          obj.timeStatus = 1
        } else if (nowTime < +obj.endTime) {
          obj.timeStatus = 0
        } else {
          obj.timeStatus = 2
        }
        this.proposalList.push(obj)
      })
      this.proposalList = [...this.proposalList].sort((a, b) => {
        if (+a.timeStatus === +b.timeStatus) {
          return +b.startTime - +a.startTime
        }
        return +a.timeStatus - +b.timeStatus
      })
    },
    handleChangeDaoAt (daoInfo, index) {
      this.currentDao = daoInfo
      this.currentIndex = index
      localStorage.setItem('daoIndex', index)
      this.handleProposalList(this.currentDao.daoAt)
    },
    async getTokenInfo () {
      const nameRes = await ContractToken.getSymbol(this.currentDao.token)
      if (nameRes.success) {
        this.tokenName = nameRes.result
      }
    },
    handleDetailShow (index) {
      this.detailShow = !this.detailShow
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  mounted () {
    this.init()
  },
  components: { NoDao, DaoDetail, DetailHeader, ProposalItem, Empty }
}
</script>

<style scoped lang="scss">
.dao-detail {
  padding: 0 15px;
  font-size: 12px;
}
</style>
