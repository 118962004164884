<template>
  <div>
    <div class="detail-header">
      <div class="dao-introduce" @click="handleDetailShow">
        {{ $t('proposal.daoIntroduce') }}
      </div>
      <div class="dao-introduce" @click="handleToProposal">
        {{ $t('proposal.putProposal') }}
      </div>
    </div>
    <div class="select" @click="handleDaoPopShow">
      <img :src="dataSource.params[0]" alt="" />
      <div class="name ellipsis">{{ currentSelect }}</div>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#xiangxiajiantou"></use>
      </svg>
    </div>
    <CommomPop v-if="daoPopShow" @close="daoPopShow = false" :closeIcon="true">
      <div class="dao-title">{{ $t('dao.daoSelect') }}</div>
      <div class="menu-box">
        <div
          class="menu-item ellipsis"
          v-for="(item, index) in daoList"
          :key="item.token"
          @click="onConfirm(item, index)"
          :class="{ active: currentSelect === item.name }"
        >
          {{ item.name }}
        </div>
      </div>
    </CommomPop>
  </div>
</template>

<script>
import CommomPop from '../../../components/common/CommomPop.vue'
export default {
  name: '',
  props: ['daoList', 'value', 'dataSource'],
  components: { CommomPop },
  data () {
    return {
      currentSelect: '',
      currentDaoAt: 0,
      daoPopShow: false,
      show: true
    }
  },
  methods: {
    handleDaoPopShow () {
      this.daoPopShow = !this.daoPopShow
    },
    onConfirm (val, index) {
      this.handleDaoPopShow()
      this.currentDaoAt = val.daoAt
      this.$emit('change', val, index)
    },
    handleDetailShow (daoAt) {
      this.$emit('show', daoAt)
    },
    handleToProposal () {
      this.$gbUtils.handleToPage(
        `/applyProposal?daoAt=${this.dataSource.daoAt}`
      )
    }
  },
  watch: {
    value (val) {
      this.currentSelect = val
    }
  },
  mounted () {
    this.currentSelect = this.value
  }
}
</script>

<style scoped lang="scss">
.detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .dao-introduce {
    width: 48%;
    text-align: center;
    height: 36px;
    font-weight: 600;
    line-height: 36px;
    color: $base-color1;
    background: $linear-bg3;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }
}
.select {
  flex: 2;
  height: 36px;
  line-height: 36px;
  background: #37374d;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: $main-color1;
  margin-bottom: 15px;
  .name {
    width: 35%;
    text-align: center;
  }
  img {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
}
.dao-title {
  font-size: 16px;
  font-weight: 600;
  color: #dbd9d5;
  line-height: 22px;
  letter-spacing: 1px;
}
.menu-box {
  max-height: 300px;
  overflow: scroll;
}
.menu-item {
  width: 95%;
  height: 40px;
  line-height: 40px;
  background: #d0ad8a;
  text-align: center;
  margin: 10px auto auto;
  color: #dbd9d5;
  font-size: 14px;
  border-radius: 4px;
  &.active {
    background: $blue-bg;
    color: #fff;
    font-weight: bold;
  }
}
</style>
